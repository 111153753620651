<template>
	<div>
		<div class="card mt-2 mx-auto">
			<b-table-simple class="table mb-0 shadow">
				<b-thead>
					<b-tr class="text-center">
						<b-th>#</b-th>
						<b-th>Descripción</b-th>
						<b-th>Valor en texto</b-th>
						<b-th>Valor en número</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(item, index) in settings" :key="index">
						<template v-if="settings.length > 0">
							<b-td class="">
								<p class="mb-0">{{ index + 1 }}</p>
							</b-td>

							<b-td>
								{{ item.description }}
							</b-td>

							<b-td>
								<div
									style="
										width: 400px;
										margin-left: auto;
										margin-right: auto;
										max-height: 10vh;
										overflow-y: auto;
										padding: 5px;
									"
								>
									{{ item.is_text === 1 ? item.value_text : "---" }}
								</div>
							</b-td>

							<b-td>
								<div>
									{{ item.is_text === 0 ? item.value_number : "---" }}
								</div>
							</b-td>

							<b-td>
								<FeatherIcon
									icon="EditIcon"
									size="20"
									class="text-primary cursor-pointer mr-50 ml-50"
									v-b-tooltip.hover
									title="Editar"
									@click="openEdit(item)"
								/>
							</b-td>
						</template>
					</b-tr>
					<b-tr v-if="settings.length == 0">
						<b-td colspan="7" class="text-center">
							<p class="mb-0 font-weight-bold">Sin registros encontrados</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>

		<b-modal
			centered
			no-close-on-backdrop
			no-close-on-esc
			v-model="showEdit.show"
			:title="'Editar configuración'"
			v-if="showEdit.show"
			@hidden="closeModal()"
		>
			<div>
				<ValidationObserver ref="form">
					<ValidationProvider class="w-100" rules="required" v-slot="{ errors }">
						<b-form-group
							id="description"
							:label="showEdit.info.is_text === 1 ? 'Valor en texto' : 'Valor en número'"
							label-for="description"
							class="w-full"
						>
							<b-form-textarea
								id="description"
								rows="3"
								v-model="showEdit.info.value_text"
								:class="{ 'border-danger rounded': !!errors[0] }"
								v-if="showEdit.info.is_text === 1"
							/>

							<b-form-input
								id="description"
								v-model="showEdit.info.value_number"
								:class="{ 'border-danger rounded': !!errors[0] }"
								v-else
							/>
						</b-form-group>
					</ValidationProvider>
				</ValidationObserver>
			</div>
			<template #modal-footer>
				<b-button variant="success" @click="editSettings">Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import commonsService from "@/services/commons.service"

export default {
	name: "GeneralSettings",
	components: {},
	data() {
		return {
			settings: [],
			showEdit: { show: false, info: {} },
		}
	},
	async mounted() {},
	computed: {},
	methods: {
		closeModal() {
			this.showEdit.show = false
		},
		openEdit(info) {
			this.showEdit.show = true
			this.showEdit.info = { ...info }
		},
		async getGeneralSettings() {
			this.isPreloading()

			try {
				const { data } = await commonsService.getGeneralSettings()
				this.settings = data.data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async editSettings() {
			if (!(await this.$refs.form.validate())) return
			this.isPreloading()

			try {
				const res = await commonsService.editSettings({
					name: this.showEdit.info.name,
					value_text: this.showEdit.info.is_text === 1 ? this.showEdit.info.value_text : null,
					value_number: this.showEdit.info.is_text === 0 ? this.showEdit.info.value_number : null,
				})
				console.log(res)
				this.showSuccessToast(res.data.message)
				this.getGeneralSettings()
				this.showEdit.show = false
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	async created() {
		await this.getGeneralSettings()
	},
}
</script>

<style lang="scss" scoped></style>
